<!-- 用户编辑弹窗 -->
<template>
    <el-dialog
            :title="isUpdate?'修改试题':'添加试题'"
            :visible="visible"
            width="60%"
            :destroy-on-close="true"
            :lock-scroll="false"
            custom-class="ele-dialog-form"
            @update:visible="updateVisible">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
<!--            <el-form-item label="标题" prop="title">
                <el-col :span="18">
                    <el-input v-model="ruleForm.title" placeholder="请输入标题"></el-input>
                </el-col>
            </el-form-item>-->
            <!--      等级-->
            <el-form-item label="等级" prop="level_id">
                <el-select :span="18"  v-model="ruleForm.level_id" placeholder="请选择等级">
                    <el-option :span="18"
                            v-for="item in roleList"
                            :label="item.name" :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <!--分数-->

            <el-form-item label="分数" prop="score">
                <el-col :span="5">
                    <el-input v-model="ruleForm.score" placeholder="请输入分数"></el-input>
                </el-col>

            </el-form-item>


            <!--分数-->
            <el-form-item label="答题时长" prop="answer_time">
                <el-col :span="5">
                    <el-input v-model="ruleForm.answer_time" oninput = "value=value.replace(/[^\d.]/g,'')" placeholder="请输入答题时长"></el-input>
                  <span style="color: red">*单位：分钟</span>
                </el-col>
            </el-form-item>
          <el-form-item
            label="题目:"
            prop="reminder">
            <el-input
              v-model="ruleForm.content"
              placeholder="请输入题目"
              clearable
              :rows="3"
              type="textarea"/>
            <span style="color: red">录入的文字用顿号(、)隔开</span>
          </el-form-item>
            <!-- 编辑器 -->
<!--            <el-form-item label="题目" prop="content">
                <el-col :span="18">
                    <tinymce-editor class="tinymce-editor" v-model="ruleForm.content" :init="init"/>
                </el-col>
            </el-form-item>-->
            <!--提交      -->
        </el-form>
        <div slot="footer">
            <el-button
                    @click="resetForm">取消
            </el-button>
            <el-button
                    type="primary"
                    @click="save"
                    :loading="loading">保存
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import TinymceEditor from '@/components/TinymceEditor';
    export default {
        name: 'UserEdit',
        props: {
            // 弹窗是否打开
            visible: Boolean,
            // 修改回显的数据
            data: Object,
          // 父组件方法，关闭弹框
          openEdit: Function
        },
        components: {TinymceEditor},
        data() {
            return {
                // 表单数据
                init: {},
                // 表单验证规则domains
                ruleForm: Object.assign({
                    level_id: '',
                    score: '',
                  answer_time:'',
                    content: '',
                }, this.data),
                // 等级下拉数据
                level_idSelection: [],
                rules: {
                    /*title: [
                        {required: true, message: '请输入标题', trigger: 'blur'}
                    ],*/
                    score: [
                        {required: true, message: '请输入分数', trigger: 'blur'}
                    ],
                    level_id: [
                        {required: true, message: '请选择等级', trigger: 'blur'}
                    ],
                    answer_time: [
                        {required: true, message: '请输入答题时长',trigger: 'blur'}
                    ],
                    content: [
                        {required: true, message: '请输入题干',trigger: 'blur'}
                    ],

                },
                // 提交状态
                loading: false,
                // 是否是修改
                isUpdate: false,
                // 角色列表
                roleList: [],

            }
        },
        //mounted
        watch: {
            //deep: true,
            data() {
              if(this.data){
                this.$http['get']('/Admin/speedInfo/'+ this.data.id).then(res => {
                  console.log(this.data)
                  this.ruleForm = Object.assign({}, this.data);
                  this.isUpdate = true;
                })
              }else{
                this.ruleForm = Object.assign({
                  level_id: '',
                  score: '',
                  answer_time:'',
                  content: '',
                }, this.data)
                this.isUpdate = false;
              }

            }
        },
        mounted() {
            this.queryRoles();  // 查询角色列表
        },
        methods: {
            /* 保存编辑 */
            save() {
                this.$refs['ruleForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true;
                        this.$http[this.isUpdate ? 'put' : 'post'](this.isUpdate ? '/Admin/speed/' + this.ruleForm.id : '/Admin/speed', this.ruleForm).then(res => {
                            this.loading = false;
                            if (res.data.code === 200) {
                                this.$message({type: 'success', message: res.data.msg});
                                if (!this.isUpdate) {
                                    this.ruleForm = {
                                      level_id: '',
                                      score: '',
                                      answer_time:'',
                                      content: ''
                                    };
                                }
                                this.updateVisible(false);
                                this.$emit('done');
                            } else {
                                this.$message.error(res.data.msg);
                            }
                        }).catch(e => {
                            this.loading = false;
                            this.$message.error(e.message);
                        });
                    } else {
                        return false;
                    }
                });
            },
          // 关闭
          resetForm(ruleForm){
            // Object.assign(this.$data.ruleForm,this.$options.data().ruleForm)
            // this.$emit('update:visible', false);
            // 调用父组件方法 隐藏弹框
            this.openEdit(null)
          },
            /* 更新visible */
            updateVisible(value) {
                this.$emit('update:visible', value);
            },
            /* 查询等级列表 */
            queryRoles() {
                this.$http.get('/Admin/level').then(res => {

                    if (res.data.code === 200) {
                        this.roleList = res.data.data;
                        //console.log(this.roleList)
                    } else {
                        this.$message.error(res.data.msg);
                    }
                }).catch(e => {
                    this.$message.error(e.message);
                });
            },
            addProblem() {
                this.ruleForm.domains.push({
                    problem: '',
                    options: [{chioptions:'',radio: 0}],
                    rightselect: '',
                    key: Date.now()
                })
            },
            deleteProblem(index) {
                this.ruleForm.domains.splice(index, 1)
            },
          // 添加
          addOptions(index){
            this.ruleForm.domains[index].options.push({
              chioptions: '',
              radio: 0,
              key: Date.now()
            })
          },
          // 删除
          removeOptions(index){
            this.ruleForm.domains[index].options.splice(this.ruleForm.domains[index].options.length - 1,1)
          }
        }
    }
</script>

<style scoped>
</style>
